/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Suspense, createContext, useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import { Switch, Route, Link, withRouter } from 'react-router-dom';

import { Layout, Menu, Button, Col, message } from 'antd';

import {
  AppstoreOutlined,
  BorderOuterOutlined,
  DatabaseOutlined,
  DollarOutlined,
  EditOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SlidersOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import Loading from '../basic/Loading';
import dashboardRoutes from './dashboardRoutes';

import './dashboard.css';
import LezzooLogo from '../basic/LezzooLogo';
import { updatePassword } from '../../api/authApi';
import { initSocket } from '../../store/socket/socketGetter';
import { useAxiosGetChat } from '../../configs/axios';
import LanguageSelector from './LanguageSelector';
import Profile from './Profile';
import UpdatePasswordModal from './UpdatePasswordModal';

const { Header, Content, Sider } = Layout;
export const NotificationsContext = createContext();

const Dashboard = ({ userStore, tokenStore, sectionsStore, permissions }) => {
  const timerRef = React.createRef();

  const { t, i18n } = useTranslation();

  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [routes, setRoutes] = useState({
    menuRoute: [],
    normalRoutes: [],
  });
  const [updateMyPassword, setUpdateMyPassword] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openKeys, setOpenKeys] = useState([]);

  const handleResize = _.debounce(() => {
    setWindowWidth(window.innerWidth);
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const socket = initSocket(userStore?.value);
  const {
    data: notificationsData,
    setData: setNotificationsData,
    request: notificationsDataRequest,
  } = useAxiosGetChat('/chats/supplier/roomCounter', {});

  const changeChatRoomCounter = (roomType, roomRef, isForMessageSeen, messageSource, number) => {
    const updatedData = {
      chatSource: roomType,
      number,
      roomRef,
      isForMessageSeen,
      actionStatus: isForMessageSeen ? 'roomSeen' : 'newMessage',
    };

    if (!isForMessageSeen && messageSource === 'supplier') {
      number = 0;
    }

    if (['supplier_customer_general', 'supplier_customer_order'].includes(roomType)) {
      setNotificationsData((prev) => {
        const { customers } = prev;
        customers.countMessages += number;
        customers.countMessages = Math.max(0, customers.countMessages);
        customers[roomType] += number;
        customers[roomType] = Math.max(customers[roomType], 0);
        return {
          ...prev,
          customers,
          updatedData,
        };
      });
    }
    if (['supplier_driver_general', 'supplier_driver_order'].includes(roomType)) {
      setNotificationsData((prev) => {
        const { drivers } = prev;
        drivers.countMessages += number;
        drivers.countMessages = Math.max(0, drivers.countMessages);
        drivers[roomType] += number;
        drivers[roomType] = Math.max(drivers[roomType], 0);
        return {
          ...prev,
          drivers,
          updatedData,
        };
      });
    }
  };

  useEffect(() => {
    if (window.location.pathname !== '/login') {
      notificationsDataRequest();
      socket?.on('counter-update', (data) => {
        changeChatRoomCounter(
          data.chatSource,
          data.roomRef,
          data.isForMessageSeen,
          data.messageSource,
          data.counter,
        );
      });
    }
    return () => {
      socket?.removeListener('counter-update');
    };
  }, []);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const logout = async () => {
    try {
      // eslint-disable-next-line max-len
      Promise.all([userStore.clear(), tokenStore.clear(), sectionsStore.clear(), permissions.clear()]).then(
        () => {
          location.reload();
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!timerRef.current) {
      timerRef.current = setTimeout(() => {
        const newRoutes = [...dashboardRoutes];
        const currentUser = permissions;
        const pers = currentUser?.value.map((per) => per.permission);
        _.remove(newRoutes, (route) => !_.includes(pers, route.path));

        const route = {
          orders: {
            label: t('orders'),
            icon: <BorderOuterOutlined />,
            routes: [],
          },
          content: {
            label: t('content'),
            icon: <EditOutlined />,
            routes: [],
          },
          user: {
            label: t('user'),
            icon: <UsergroupAddOutlined />,
            routes: [],
          },
          report: {
            label: t('report'),
            icon: <DatabaseOutlined />,
            routes: [],
          },
          price: {
            label: t('price'),
            icon: <DollarOutlined />,
            routes: [],
          },
          app: {
            label: t('app_&_web'),
            icon: <AppstoreOutlined />,
            routes: [],
          },
          other: {
            is_flat_menu: true,
            label: t('others'),
            icon: <SlidersOutlined />,
            routes: [],
          },
        };

        for (let i = 0; i < newRoutes.length; i += 1) {
          const element = newRoutes[i];
          if (
            element.name == 'orders'
            || element.name == 'client_orders'
            || element.name == 'offline_orders'
            || element.name == 'purchase_order'
            || element.name == 'minimum_order'
            || element.name == 'cash_info'
            || element.name == 'cart_change_logger'
            || element.name == 'order_status_change_logger'
            || element.name == 'return_fulfillment'
          ) {
            route.orders.routes.push(element);
          } else if (
            element.name == 'categories'
            || element.name == 'products'
            || element.name == 'verticals'
            || element.name == 'variant_groups'
            || element.name == 'brands'
            || element.name == 'tags'
            || element.name == 'B2B B2C Linking'
            || element.name === 'banners'
            || element.name === 'segments'
            || element.name === 'common_catalogs'
          ) {
            route.content.routes.push(element);
          } else if (
            element.name == 'users_account'
            || element.name == 'clients'
            || element.name == 'customers'
            || element.name == 'client_calendar'
          ) {
            route.user.routes.push(element);
          } else if (element.name == 'analytics' || element.name == 'logger') {
            route.report.routes.push(element);
          } else if (
            element.name == 'delivery_prices'
            || element.name == 'delivery_caps'
            || element.name == 'customer_credit'
            || element.name == 'client_payments'
            || element.name == 'sku_discounts'
          ) {
            route.price.routes.push(element);
          } else if (element.name == 'apps' || element.name == 'layout' || element.name == 'notifications') {
            route.app.routes.push(element);
          } else {
            route.other.routes.push(element);
          }
        }

        setRoutes({
          menuRoute: Object.values(route),
          normalRoutes: newRoutes,
        });

        for (const iterator of permissions.value) {
          if (iterator.permission === 'ResetPersonalPassword') {
            setUpdateMyPassword(true);
          }
        }
      }, 100);
    }
  }, []);

  const onClickUpdatePassword = async (values) => {
    setResetPasswordLoading(true);
    try {
      await updatePassword(
        userStore?.value?.user_name,
        values.old_password,
        values.new_password,
        JSON.parse(localStorage.getItem('cs-token')).value,
      );
      message.success('Password updated successfully');
      setVisible(false);
      setResetPasswordLoading(false);
      logout();
    } catch (error) {
      setResetPasswordLoading(false);
      message.error(error?.response?.data?.msg || error.message);
    }
  };

  return (
    <NotificationsContext.Provider value={[notificationsData, setNotificationsData]}>
      {/* <AskForHelp /> */}
      <UpdatePasswordModal
        onClickUpdatePassword={onClickUpdatePassword}
        setVisible={setVisible}
        visible={visible}
        resetPasswordLoading={resetPasswordLoading}
      />
      <Layout
        style={{
          minHeight: '100vh',
          display: loading ? 'none' : undefined,
        }}
      >
        <Sider
          theme="dark"
          className={'layout_sider'} // ${siderClass}
          width={240}
          collapsed={collapsed}
          collapsedWidth={windowWidth < 768 && collapsed ? 0 : 70}
          style={{
            height: '100vh',
            // left: 0,
            position: 'absolute',
            overflow: 'auto',
            // zIndex:-1
          }}
        >
          <div className={collapsed ? 'logo_small' : 'logo'}>
            <LezzooLogo collapsed={collapsed} />
          </div>
          {windowWidth < 768 && (
            <Col>
              <div className="sider_toggler_btn">
                <Button
                  type="link"
                  icon={<MenuFoldOutlined style={{ color: '#fff' }} />}
                  onClick={toggleSidebar}
                  style={{
                    textAlign: 'start',
                    paddingLeft: 10,
                    paddingRight: undefined,
                  }}
                />
              </div>
            </Col>
          )}
          <Menu
            mode="inline"
            // className="sider_menu_links"
            // items={routes}
            // defaultOpenKeys={[0]}
            selectedKeys={location.pathname}
            openKeys={openKeys}
            onOpenChange={(keys) => {
              const rootSubmenuKeys = ['0', '1', '2', '3', '4', '5', '6'];
              const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
              if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                setOpenKeys(keys);
              } else {
                setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
              }
            }}
          >
            {routes.menuRoute.map((route, index) => {
              if (route.routes.length) {
                if (route.is_flat_menu) {
                  return route.routes
                    .filter((singleRoute) => !singleRoute.hidden)
                    .map((item) => (
                      <Menu.Item key={item.path}>
                        {item.icon}
                        <span>{i18n.t(item.name)}</span>
                        <Link to={item.path} />
                      </Menu.Item>
                    ));
                }

                return (
                  <Menu.SubMenu icon={route.icon} title={route.label} key={index}>
                    {route.routes
                      .filter((singleRoute) => !singleRoute.hidden)
                      .map((item) => (
                        <Menu.Item key={item.path}>
                          {item.icon}
                          <span>{i18n.t(item.name)}</span>
                          <Link to={item.path} />
                        </Menu.Item>
                      ))}
                  </Menu.SubMenu>
                );
              }
              return null;
            })}
            {/* {routes
              .filter((route) => !route.hidden)
              .map((item, index) => (
                <Menu.Item key={item.path}>
                  {item.icon}
                  <span>{i18n.t(item.name)}</span>
                  <Link to={item.path} />
                </Menu.Item>
              ))} */}
          </Menu>
        </Sider>

        <Layout
          className={'site-layout'}
          style={{
            transition: 'all 0.3s',
            marginLeft: i18n.language === 'en' ? (collapsed ? 80 : 240) : '0',
            marginRight: i18n.language === 'kr' || i18n.language === 'ar' ? (collapsed ? 80 : 240) : '',
          }}
        >
          <Header
            className="site-layout-background"
            style={{ padding: 0, display: 'inherit', position: 'relative' }}
          >
            <Col>
              <div className="sider_toggler_btn">
                <Button
                  type="link"
                  icon={
                    collapsed ? (
                      <MenuUnfoldOutlined style={{ color: '#1f2532' }} />
                    ) : (
                      <MenuFoldOutlined style={{ color: '#1f2532' }} />
                    )
                  }
                  onClick={toggleSidebar}
                  style={{
                    textAlign: 'start',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                />
              </div>
            </Col>
            <div className="main_header_container">
              {/* <div className="section_title"></div> */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* this is Profile componenet  */}
                <Profile setVisible={setVisible} updateMyPassword={updateMyPassword} logout={logout} />
                {/* this is languge selector compoenet   */}
                <LanguageSelector />
              </div>
            </div>
          </Header>
          <Content style={{ margin: '16px 16px' }}>
            <div
              className="site-layout-background"
              style={{
                padding: '8px',
                paddingRight: i18n.language == 'en' ? 40 : 0,
                paddingLeft: i18n.language !== 'en' ? 40 : 0,
                minHeight: 600,

                backgroundColor: 'white',
              }}
            >
              <Suspense fallback={<Loading />}>
                <Switch>
                  {routes.normalRoutes.map((route) => (
                    <Route exact={route.exact} key={route.path} path={`${route.path}`}>
                      <route.component />
                    </Route>
                  ))}
                </Switch>
              </Suspense>
            </div>
          </Content>
        </Layout>
      </Layout>
    </NotificationsContext.Provider>
  );
};

export default inject(
  'userStore',
  'tokenStore',
  'sectionsStore',
  'permissions',
)(observer(withRouter((props) => <Dashboard {...props} />)));
